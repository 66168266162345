<template>
  <a-modal
    v-model="configSourceVisible"
    title="配置广告源到预算包"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="'1000px'"
  >
    <div class="config-source-modal-wrapper">
      <div class="config-source-header">
        <div class="info-item">
          <span v-if="!isAdd" class="label">预算包名称：</span>
          <span v-if="!isAdd" class="name">{{ budgetInfo.name }}</span>
          <a-form-model ref="ruleForm" :model="addquery" v-if="isAdd" class="form">
            <a-form-model-item :rules="[{ required: true, message: '请输入包名', trigger: 'blur' }]" prop="name">
              <span class="label">预算包名称：</span>
              <a-input
                placeholder="请输入预算包名称"
                v-model="addquery.name"
                style="width: 200px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <span style="margin: 0 5px 0 10px">默认预算包</span>
              <a-switch :checked="+addquery.isDefault === 1" @change="changeIsDefault" />
            </a-form-model-item>
            <a-form-model-item
              v-if="addquery.isDefault"
              :rules="[{ required: true, message: '请选择操作系统', trigger: 'change' }]"
              prop="osList"
            >
              {{ addquery.osList }}
              <m-select-more
                style="width: 200px"
                v-model="addquery.osList"
                :allData="[
                  { id: '1', name: '安卓' },
                  { id: '0', name: 'IOS' }
                ]"
                :searchById="false"
                :hasIcon="false"
                :showId="false"
                label="操作系统"
                :showSelectedPart="true"
                width="450px"
                :canSelectAll="true"
              />
            </a-form-model-item>
            <a-form-model-item
              v-if="addquery.isDefault"
              :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
              prop="positionList"
            >
              <m-select-more
                style="width: 200px"
                v-model="addquery.positionList"
                :allData="positionList || []"
                :searchById="false"
                :hasIcon="false"
                :showId="false"
                label="广告样式"
                :showSelectedPart="true"
                width="450px"
                :canSelectAll="true"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div class="contral-box">
        <div class="source-title">广告源列表</div>
        <div class="search-box">
          <m-select-more
            class="search_item"
            v-model="query.platIdList"
            :allData="platList"
            :searchById="false"
            :hasIcon="false"
            :showId="false"
            label="广告平台"
            :showSelectedPart="true"
            width="450px"
            :canSelectAll="true"
            @change="getRtbPlatAccounts"
          />
          <m-select-more
            class="search_item"
            v-model="query.platAccountIdList"
            :allData="platAccountList"
            :searchById="false"
            :hasIcon="false"
            :showId="false"
            label="平台账号"
            :showSelectedPart="true"
            width="450px"
            :canSelectAll="true"
          />
          <a-button type="primary" @click="getTreeList">查询</a-button>
        </div>
      </div>
      <div class="config-source-main">
        <div class="config-source-main-left right-border">
          <div class="search-wrapper">
            <a-input v-model="searchKey" placeholder="输入广告源名称/账号名称搜索"></a-input>
          </div>
          <div class="account-list-wrapper">
            <a-spin :spinning="isLoading">
              <div class="account-item" v-for="account in searchList" :key="account.platAccountId">
                <div class="account-box" @click="changeIsExpand($event, account)">
                  <a-checkbox
                    class="account-checkbox"
                    :indeterminate="account.indeterminate"
                    :checked="account.checkAll"
                    @click="changeAccountChecked($event, account)"
                  >
                    {{ account.platAccountName }}
                  </a-checkbox>
                  <a-icon :type="!account.isExpand ? 'down' : 'up'" class="account-arrow icon" />
                </div>
                <div class="source-list" v-if="account.isExpand">
                  <a-checkbox
                    class="source-checkbox"
                    v-for="source in account.childList"
                    :key="source.dspId"
                    :checked="source.checked"
                    @change="changeSourceChecked($event, account, source)"
                  >
                    {{ source.dspName }}
                  </a-checkbox>
                </div>
              </div>
            </a-spin>
          </div>
        </div>
        <div class="config-source-main-right">
          <div class="config-source-main-right-header">
            <span>已选{{ configuredList.length }}项</span>
            <a-button type="link" size="small" @click="handleClearAll">全部清除</a-button>
          </div>
          <div class="check-list">
            <div v-for="(source, index) in configuredList" :key="source.dspId" class="check-item">
              <div class="check-source-name">{{ source.dspName }}</div>
              <a-icon
                v-if="!+source.configured"
                @click.stop="handleCircleSource(index)"
                type="close-circle"
                class="close-icon"
              />
              <a-popconfirm
                v-if="+source.configured"
                title="确认移除已配置广告源?"
                ok-text="是"
                cancel-text="否"
                @confirm="handleCircleSource(index)"
              >
                <a-icon type="close-circle" class="close-icon" />
              </a-popconfirm>
            </div>
            <m-empty v-if="configuredList.length === 0" style="height: 341px" imgHeight="176px">
              <template #description> 暂未选择广告源 </template>
            </m-empty>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getBudgetDspList, updateBudget, addBudget } from '@/apiForManage/strategy'
import { getApiPlatformList } from '@/apiForManage/aggregate'
import { getRtbPlatAccounts } from '@/apiForManage/common'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import mixGetList from '@/mixins/getListForManage'

export default {
  name: 'BatchConfigBudgetSourceModal',
  mixins: [mixGetList],
  components: { IosSvg, AndroidSvg },
  data () {
    return {
      configuredList: [],
      allAccountList: [],
      searchKey: undefined,
      query: {},
      hasGetChecked: false,
      isLoading: false,
      platAccountList: [],
      addquery: {
        name: undefined,
        positionList: [],
        osList: [],
        isDefault: 0
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    budgetInfo: {
      type: Object,
      default: () => ({})
    },
    isAdd: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          // 每次打开弹窗时，清空搜索字段
          this.searchKey = ''
          this.hasGetChecked = false
          this.addquery = {
            name: undefined,
            positionList: [],
            osList: [],
            isDefault: 0
          }
          this.query = {
            id: this.budgetInfo.id,
            platIdList: [],
            platAccountIdList: [],
            name: undefined
          }
          this.getTreeList()
          this.getRtbPlatAccounts()
        }
      }
    }
  },
  computed: {
    // 左侧树形列表
    searchList () {
      if (this.searchKey === undefined && !this.searchKey) {
        // 搜索字段为空，返回全部数据
        return this.allAccountList
      } else {
        // 字段搜索返回相应的账号和广告源
        let arr = []
        this.allAccountList.forEach((item) => {
          if (
            item.platAccountName?.includes(this.searchKey) ||
            item.childList.some((source) => source.dspName?.includes(this.searchKey))
          ) {
            arr = [...arr, item]
          }
        })
        return arr
      }
    },
    configSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  async created () {
    this.getApiPlatform()
    await this.getPositionList()
    this.positionList.forEach((item) => {
      item.id = '' + item.id
    })
  },
  methods: {
    changeIsDefault (e) {
      this.addquery.isDefault = e ? 1 : 0
    },
    // 获取api平台列表
    async getApiPlatform () {
      const { data = [] } = await getApiPlatformList()
      this.platList = data
    },
    async getRtbPlatAccounts (ids = []) {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: ids
      })
      this.platAccountList = data
    },
    // changeTrafficPackage (e) {
    //   this.query.trafficPackage = e.target.checked ? 1 : 0
    //   this.getTreeList()
    // },
    /**
     * 账号多选框点击事件
     * @param {*} e 账号点击事件
     * @param {*} account 账号信息
     */
    changeAccountChecked (e, account) {
      // 选中状态
      const checked = e.target.checked
      account.childList.forEach((source) => {
        if (checked && !source.checked) {
          this.configuredList = [...this.configuredList, source]
        } else if (!checked && source.checked) {
          this.configuredList = this.configuredList.filter((item) => item.dspId !== source.dspId)
        }
        source.checked = checked
      })
      this.handleCheck()
    },
    /**
     * 广告源多选框点击事件
     * @param {*} e 账号多选框点击事件
     * @param {*} account 账号对象
     * @param {*} source 广告源对象
     */
    changeSourceChecked (e, account, source) {
      // 选中状态
      const checked = e.target.checked
      if (checked) {
        // 选中状态
        this.configuredList = [...this.configuredList, source]
      } else {
        // 未选中状态
        this.configuredList.splice(
          this.configuredList.findIndex((item) => item.dspId === source.dspId),
          1
        )
      }
      this.handleCheck()
    },
    // 点击展开图标
    changeIsExpand (e, account) {
      if (e.target.classList[0] === 'account-box' || e.target.tagName === 'svg') {
        account.isExpand = !account.isExpand
      }
    },
    // 获取账号广告源树形数据和当前已配置广告源列表
    async getTreeList () {
      this.isLoading = true
      const { data = {} } = await getBudgetDspList(this.query)
      const { configuredList = [], items = [] } = data
      !this.hasGetChecked && (this.configuredList = configuredList)
      this.hasGetChecked = true
      this.allAccountList = items
      this.handleCheck()
      this.isLoading = false
    },
    // 处理选中或取消广告源后，树形结构数据的变化，统一处理，只需完成已勾选广告源
    handleCheck () {
      // 选中的广告源id列表
      const checkedList = this.configuredList.map((item) => item.dspId)
      this.allAccountList.forEach((account, index) => {
        ;(account.childList || []).forEach((source) => {
          // 广告源选中状态
          this.$set(source, 'checked', checkedList.includes(source.dspId))
        })
        // 账号选中状态
        this.$set(
          account,
          'indeterminate',
          account.childList.filter((item) => item.checked).length > 0 &&
            account.childList.filter((item) => item.checked).length < account.childList.length
        )
        // 账号全选状态
        this.$set(
          account,
          'checkAll',
          account.childList.filter((item) => item.checked).length === account.childList.length
        )
        // 账号是否展开
        this.$set(account, 'isExpand', !!account.isExpand)
      })
    },
    // 删除选中点击事件icon
    handleCircleSource (index) {
      this.configuredList.splice(index, 1)
      this.handleCheck()
    },
    // 删掉所有选中
    handleClearAll () {
      this.configuredList = []
      this.handleCheck()
    },
    handleCancel () {
      this.configSourceVisible = false
    },
    async handleSubmit () {
      if (this.isAdd) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            const dspIdList = this.configuredList.map((item) => item.dspId)
            const resp = await addBudget({
              ...this.addquery,
              dspIdList
            })
            if (resp.code === 200) {
              this.configSourceVisible = false
              this.$emit('addSourceSuccess')
              this.$message.success('添加成功！')
            }
          } else {
            return false
          }
        })
        // if (!this.addquery.name) {
        //   return this.$message.error('请输入预算包名称')
        // }
      } else {
        const dspIdList = this.configuredList.map((item) => item.dspId)
        const resp = await updateBudget({
          dspIdList,
          ...this.budgetInfo
        })
        if (resp.code === 200) {
          this.configSourceVisible = false
          this.$emit('configSourceSuccess')
          this.$message.success('绑定成功！')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.config-source-modal-wrapper {
  user-select: none;
  padding: 20px;
  .config-source-header {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    .info-item {
      margin-right: 40px;
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      .label {
        font-weight: 600;
      }
      .form{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .contral-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .source-title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      margin: 20px 0;
      margin-right: 20px;
    }
    .search-box {
      .search_item {
        width: 180px;
        margin-right: 10px;
      }
    }
  }
  .config-source-main {
    border: 1px solid #e8e8e8;
    height: 380px;
    display: flex;
    .config-source-main-left,
    .config-source-main-right {
      flex-grow: 1;
      flex-shrink: 1;
      width: 50%;
      &.right-border {
        border-right: 1px solid #e8e8e8;
      }
    }
    .config-source-main-left {
      display: flex;
      flex-direction: column;
      .search-wrapper {
        min-height: 55px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }
      .account-list-wrapper {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        padding: 10px 20px;
        .account-item {
          border-bottom: 1px solid #e8e8e8;
          padding: 5px 0 10px 0;
          .account-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .account-checkbox {
              margin-top: 10px;
              margin-bottom: 10px;
            }
            .icon {
              width: 14px;
              height: 14px;
              margin-left: 5px;
              &.account-arrow {
                cursor: pointer;
              }
            }
          }
          .source-list {
            display: flex;
            flex-direction: column;
            .source-checkbox {
              margin-left: 20px;
              margin-top: 5px;
              margin-bottom: 5px;
              padding: 0;
            }
          }
        }
      }
    }
    .config-source-main-right {
      display: flex;
      flex-direction: column;
      .config-source-main-right-header {
        min-height: 55px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
      }
      .check-list {
        height: 0;
        flex-grow: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .check-item {
          display: flex;
          align-items: center;
          padding: 8px 30px;
          .check-source-name {
            width: 400px;
            height: 34px;
            background: #eaefff;
            line-height: 34px;
            padding-left: 20px;
            border-radius: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .close-icon,
          .setting-icon {
            margin-left: 10px;
            color: #858FA1;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
