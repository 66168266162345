// 弃用
import request from '@/utils/request'

const api = {
  getBudgetPage: '/budget/page',
  addBudget: '/budget/add',
  changeBudgetSte: '/budget/updateSts',
  getDspListByBudget: '/budget/dspListByBudget',
  updateBudget: '/budget/update',
  getBudgetPlaceTreeList: '/budget/placeList',
  bindBudgetPlaceList: '/budget/bindPlace',
  getTrafficPage: '/traffic/page',
  changeTrifficSte: '/traffic/updateSts',
  addTraffic: '/traffic/add',
  updateTraffic: '/traffic/update',
  getPlaceListByTraffic: '/traffic/placeListByTraffic',
  getTrafficPlaceList: '/traffic/placeList',
  getBudgetDspList: '/budget/dspList'
}

export default api

export function getBudgetPage (data) {
  return request({
    url: api.getBudgetPage,
    method: 'post',
    data
  })
}

export function addBudget (data) {
  return request({
    url: api.addBudget,
    method: 'post',
    data
  })
}

export function changeBudgetSte (data) {
  return request({
    url: api.changeBudgetSte,
    method: 'post',
    data
  })
}

export function getDspListByBudget (data) {
  return request({
    url: api.getDspListByBudget,
    method: 'post',
    data
  })
}

export function updateBudget (data) {
  return request({
    url: api.updateBudget,
    method: 'post',
    data
  })
}

export function getBudgetPlaceTreeList (data) {
  return request({
    url: api.getBudgetPlaceTreeList,
    method: 'post',
    data
  })
}

export function bindBudgetPlaceList (data) {
  return request({
    url: api.bindBudgetPlaceList,
    method: 'post',
    data
  })
}

export function getTrafficPage (data) {
  return request({
    url: api.getTrafficPage,
    method: 'post',
    data
  })
}

export function changeTrifficSte (data) {
  return request({
    url: api.changeTrifficSte,
    method: 'post',
    data
  })
}

export function addTraffic (data) {
  return request({
    url: api.addTraffic,
    method: 'post',
    data
  })
}

export function updateTraffic (data) {
  return request({
    url: api.updateTraffic,
    method: 'post',
    data
  })
}

export function getPlaceListByTraffic (data) {
  return request({
    url: api.getPlaceListByTraffic,
    method: 'post',
    data
  })
}

export function getTrafficPlaceList (data) {
  return request({
    url: api.getTrafficPlaceList,
    method: 'post',
    data
  })
}

export function getBudgetDspList (data) {
  return request({
    url: api.getBudgetDspList,
    method: 'post',
    data
  })
}
